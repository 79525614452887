import Button from "components/Button/Button";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./pwaCheck.styles.css";

export const PWAChecker = () => {
    const [isInstalled, setIsInstalled] = useState(false);
    const [initModal, setInitModal] = useState(false);
    const [installPrompt, setInstallPrompt] = useState(null);
    const checkIsInstalled = async () => {
        const appInstalled = await navigator.getInstalledRelatedApps();
        setInitModal(true);
        if (appInstalled.length > 0) {
            setIsInstalled(true);
        }

    }
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            setInstallPrompt(event);
            checkIsInstalled();
        });
    }, []);

    const installPWA = async () => {
        if (!installPrompt) return;
        await installPrompt.prompt();
    }
    return (
        <ReactModal isOpen={initModal && !isInstalled} style={{
            overlay: {
                overflow: "auto",
                backgroundColor: "rgba(var(--background-color-secondary-rgb), 0.7)",
            },
            content: {
                padding: "2rem",
                margin: "auto",
                marginTop: "5vh",
                marginBottom: "5vh",
                width: "fit-content",
                height: "fit-content"
            }
        }}>
            <div className="pwaModalContent">
                <img src="/assets/icons/android-chrome-192x192.png" alt="" />
                <h2>{t("PWAMsg.Title")}</h2>
                <p>{t("PWAMsg.Message")}</p>
                <div className="d-flex mt-3 gap-3">

                    <Button className="btn-blue " onClick={installPWA}>
                        {t("PWAMsg.btnInstall")}
                    </Button>
                    <Button className="btn-red" onClick={() => setInitModal(false)}>
                        {t("PWAMsg.btnCancel")}
                    </Button>
                </div>
            </div>
        </ReactModal>
    );
};
