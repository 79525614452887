import { Icon } from "@iconify/react"
import Button from "components/Button/Button"
import { t } from "i18next"
import { confirmAlert } from "react-confirm-alert"

// This Prompt is only for devices which have PWA support and cannot install automatically
export const manualPrompt = async ({ doNotShowAgain, alreadyInstalled, isIOS, isAndroid }) => {
    confirmAlert({
        customUI: ({ onClose: close }) => {
            return <>
                <div className="pwaModal">
                    <div className="flex justify-end">
                        <Icon
                            icon="zondicons:close-solid"
                            width={20}
                            className="mr-3 h-6 sm:h-9 cursor-pointer"
                            onClick={close}
                        />
                    </div>
                </div>
                <div className="pwaModalContainer">
                    <div className="flex flex-row justify-between gap-6">
                        <div className="flex items-start -mr-6">
                            <img src="https://files.onlyall.fr/1%2FImages%2FLogo%2FJOONSOFT%20LOGOTYPE%20OFFICIEL_2023-05-02T162728.png.jpg" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <h4 className="text-[18px] font-bold">
                                {t('PWAMsg.Title')}
                            </h4>
                            <p className="text-[14px]">
                                {t('PWAMsg.Message')}
                            </p>
                            <div className="flex justify-start">
                                {/* Prompt for ios Devices */}
                                {isIOS && !isAndroid ?
                                    <div className="flex flex-col gap-2 mt-2 text-[14px]">
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            1.  {t('PWAMsg.ClickThe')} <Icon icon="material-symbols:ios-share" /> {t('PWAMsg.Icon')}
                                        </div>
                                        2. {t('PWAMsg.ScrollDown')}
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            3.  {t('PWAMsg.AddToHomeScreen')} <Icon icon="icon-park-outline:add" />
                                        </div>
                                    </div>
                                    :
                                    <div className="flex flex-col gap-2 mt-2 text-[14px]">
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            1. {t('PWAMsg.ClickThe')} <Icon icon="zondicons:dots-horizontal-triple" /> {t('PWAMsg.Icon')}
                                        </div>
                                        2.  {t('PWAMsg.ScrollDown')}
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            3. <Icon icon="ic:baseline-add-to-home-screen" /> {t('PWAMsg.AddToHomeScreen')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <Button
                            className="gray"
                            onClick={() => alreadyInstalled(close)}
                        >
                            {t('PWAMsg.AlreadyInstalled')}
                            <Icon
                                icon="fluent:checkbox-checked-20-filled"
                                className="ml-2 text-green-500"
                                width={20}
                            />
                        </Button>
                        <Button
                            className="gray"
                            onClick={() => doNotShowAgain(close)}
                        >
                            {t('PWAMsg.DontShowItAgin')}
                            <Icon
                                icon="teenyicons:message-no-access-solid"
                                className="ml-2 text-blue-500"
                                width={20}
                            />
                        </Button>
                    </div>
                </div>
            </>
        },
    })
}