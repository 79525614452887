export const getRightFromTypeId = (typeId) => {
	switch (typeId) {
		case 1:
			return "AdminNews";
		case 3:
			return "AdminArticle";
		case 4:
			return "AdminAds";
		case 13:
			return "AdminAlert";
		case 14:
			return "AdminProAds";
		case 5:
			return "AdminCareer";
		case 8:
			return "AdminEvent";
		//Fake id to have the same behavior as the others
		case "chartTypeId":
			return "AdminChart";
		case "documentTypeId":
			return "AdminDocument";
		case "formTypeId":
			return "AdminForms";
		case "rssTypeId":
			return "AdminRss";
		case "contactTypeId":
			return "AdminEmploye";
		default:
			return "";
	}
};

export const getWriteRightFromTypeId = (typeId) => {
	switch (typeId) {
		case 1:
			return "WriteNews";
		case 3:
			return "WriteArticle";
		case 4:
			return "WriteAds";
		case 13:
			return "WriteAlert";
		case 14:
			return "WriteProAds";
		case 5:
			return "WriteCareer";
		case 8:
			return "WriteEvent";
		//Fake id to have the same behavior as the others
		case "chartTypeId":
			return "WriteChart";
		case "documentTypeId":
			return "WriteDocument";
		case "formTypeId":
			return "WriteForm";
		case "rssTypeId":
			return "WriteRss";
		case "contactTypeId":
			return "WriteEmploye";
		case "siteTypeId":
			return "WriteSites";
		default:
			return "";
	}
};
