export const TypeRights = {
	AdminWFU: "AdminWFU",
	AdminWFY: "AdminWFY",
	AdminAppearance: "AdminAppearance",
	AdminCategories: "AdminCategories",
	AdminGroups: "AdminGroups",
	AdminSites: "AdminSites",
	AdminChart: "AdminChart",
	AdminDocument: "AdminDocument",
	AdminForms: "AdminForms",
	AdminArticle: "AdminArticle",
	AdminAlert: "AdminAlert",
	AdminAppFunctions: "AdminAppFunctions",
	AdminEvent: "AdminEvent",
	AdminNews: "AdminNews",
	AdminAds: "AdminAds",
	AdminProAds: "AdminProAds",
};

export const WriteRights = {
	WriteNews: "WriteNews",
	WriteAds: "WriteAds",
	WriteProAds: "WriteProAds",
	WriteCareer: "WriteCareer",
	WriteArticle: "WriteArticle",
	WriteChart: "WriteChart",
	WriteDocument: "WriteDocument",
	WriteEvent: "WriteEvent",
	WriteEmploye: "WriteEmploye",
	WriteRss: "WriteRss",
	WriteForm: "WriteForm",
	WriteAlert: "WriteAlert",
};
export const returnRightsFromName = (rights, name) =>
	rights && rights.filter((right) => right.Name === name).length > 0;
