export const en_EN = {
	Code: "en-EN",
	CodeMin: "en",
	Server: {
		CONNECTING: "Connecting to the server",
		PING_ERROR: "Error connecting to server",
		TIMEOUT: "The server took too long to respond",
		RETRY_LATER:
			"Please check your internet connection,  or try again later, thank you",
	},
	Desktop: {
		LOAD_ERROR: "An error occurred while loading the Desktop",
		ERROR: "Error",
		ERROR_404: "Error 404",
		PAGE_NOT_FOUND: "The page you are looking for does not exist",
		UNAUTHORIZED:
			"You cannot access this page, please contact an administrator",
		ALLOW_COOKIES: "Accept cookies from",
		TO_SHOW_CONTENT: "to show the content",
		ACCEPT: "Accept",
		RELOAD_PAGE: "Reload page",
	},
	Create: {
		ENTER_INFOS: "Enter your informations",
		CHOOSE_PASS: "Choose a password",
		PASSWORD: "Password",
		PASS_CONFIRM: "Password confirmation",
		NETWORK_NAME: "Network name",
		NETWORK_LOGIN: "Network identifier",
		LOGIN_EXAMPLE: "Login example",
		LOGO: "Logo (optional)",
		ACCEPT: "Accept the",
		CGU: "general conditions of use",
		BACK_TO_LOGIN: "Return to the login page",
		ERROR_NO_FIRSTNAME: "Please enter your first name",
		ERROR_NO_LASTNAME: "Please enter your last name",
		ERROR_NO_MAIL: "Please enter a valid email address",
		ERROR_NO_PSW_MATCH: "Passwords do not match",
		ERROR_CGU_NOT_ACCEPTED: "Please accept the terms of use",
		ERROR_NAME_ALREADY_IN_USE:
			"The company name is already taken, please choose another one",
	},
	Commons: {
		WELCOME: "Welcome",
		ERROR: "An error occurred",
		PAGE_LOAD: "Page is loading",
		LOADING: "Loading...",
		OPTIONS: "Options",
		IDENTITY: "Identity",
		PUBLISH_OPTIONS: "Publishing options",
		DEFERRED_PUBLISH: "Deferred publication",
		DEFERRED: "deferred",
		DEFERRED_TO: "Deferred to",
		PRIORITY_PUBLISH: "Priority publication",
		PRIORITY_UNTIL: "Priority until",
		SEND_STEP: "Send",
		END_STEP: "End",
		SENDING: "Sending ...",
		CLOSE: "Close",
		BACK: "Back",
		ADD: "Add",
		CREATE: "Create",
		APPLY: "Apply",
		CANCEL: "Cancel",
		CONFIRM: "Confirm",
		SAVE: "Save",
		IMPORT: "Import",
		DROP: "Drag/drop your file here",
		MODIFY: "Modify",
		MODIFIED: "Modified",
		DUPLICATE: "Duplicate",
		REJECT: "Reject",
		PUBLISH: "Publish",
		SELECTED: "Selected",
		DELETE: "Delete",
		DELETING: "Deleting",
		ITEM: "item",
		ITEMS: "items",
		ITEMS_one: "{{count}} item",
		ITEMS_other: "{{count}} items",
		SEARCH: "Search",
		ALL_SITES: "All sites",
		ALL_CATEGORIES: "All categories",
		CATEGORIES: "Categories",
		SELECT_SITES: "Select one or more sites",
		SELECT_CATEGORIES: "Select one or more categories",
		PUBLISH_BY_SITES: "Publish by sites",
		FAVORITES: "Favorites",
		FAVORITE: "Favorite",
		ADD_FAVORITE: "Add to favorites",
		DEL_FAVORITE: "Remove from favorites",
		AT: "at",
		THE: "The",
		FROM: "From",
		TO: "To",
		LINK: "Link",
		LINKS: "Links",
		COPY_LINK: "Copy link",
		LINK_COPIED_TO_CLIPBOARD: "Link copied to clipboard",
		DATA_COPIED_TO_CLIPBOARD: "Datas copied to clipboard",
		SEND_BY_MAIL: "Send by mail",
		NOTIFY_USERS: "Notify users",
		NOTIFICATION: "Notification",
		SENDING_NOTIF: "Sending notification",
		CHANGE_DISP: "Change display",
		NAME_ERROR: "The element must have a name",
		PREVIEW_LOAD: "Loading preview",
		NEW: "New",
		TARGETS: "Targets",
		TARGETS_zero: "No targets",
		TARGETS_one: "{{count}} Target",
		TARGETS_other: "{{count}} Targets",
		REPORT_BTN: "Report a problem",
		REPORT: "Report",
		USER: "User",
		ADMIN: "Admin",
		DRAFTS: "Drafts",
		SHOW_DRAFTS: "Show drafts",
		USE_AS_TEMPLATE: "Use as template",
		LIST_END: "End of list",
		NO_RESULT: "No result",
		NO_RESULT_FOR: "No result for",
		FILTERS: "Filters",
		LAST_CHANGED: "Last changed",
		ASK_SAVE: "Do you want to save changes?",
		ASK_CONTINUE: "Do you want to continue?",
		ASKDELETE_one:
			'Item "{{name}}" will be deleted. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} items will be deleted. $t(Commons.ASK_CONTINUE)",
		DELETING_one: "Deleting item",
		DELETING_other: "Deleting items",
		CUSTOM_FORMAT_DATE: "M-D-Y",
		SHARE: "Share",
		AVAILABLES: "Availables",
		HIGHLIGHT: "Highlight",
		NO_PUBLICATIONS: "No publications",
	},
	Targets: {
		PUBLISHED: "Published",
		published_sites_one: "$t(Targets.PUBLISHED) on {{count}} site",
		published_sites_other: "$t(Targets.PUBLISHED) on {{count}} sites",
		published_sites_all: "$t(Targets.PUBLISHED) on all sites",

		published_groups_one: "$t(Targets.PUBLISHED) for {{count}} group",
		published_groups_other: "$t(Targets.PUBLISHED) for {{count}} groups",
		published_groups_all: "$t(Targets.PUBLISHED) for all groups",
	},
	Joyride: {
		CLOSE: "Close",
		BACK: "Back",
		NEXT: "Next",
		LAST: "End",
		OPEN: "Open the dialog",
		SKIP: "Skip",
	},
	HelperTourEditor: {
		STEPS_one: "{{count}} Step",
		STEPS_other: "{{count}} Steps",
		ADD_NODE: "Add a node",
		HELP_MSG1: "Please enter a title for the step",
		HELP_MSG2: "Please select a target for the step",
		TARGET_ELEMENT: "Targeted element",
	},
	Editing: {
		UNSAVED_WARN: "WARNING, unsaved changes will be lost",
		NO_SAVE: "Close without saving",
		DRAFT_SAVE: "Save as draft",
		CONTINUE: "Continue editing",
	},
	Toasts: {
		PROM_PENDING: "Sending datas 🚀",
		PROM_SUCCESS: "Everything went well 👌",
		PROM_ERROR: "En error occured 🤯",
	},
	Reporting: {
		TITLE: "Error report",
		REQUEST: "Request",
		ERROR: "Error",
		DATAS: "Datas",
		USER: "User",
		MSG1: "As",
		INFORMATIONS: "Informations",
		SEND_REPORT: "Send a report?",
		REPORTING: "Sending error report 🚀",
		REPORT_SUCCESS: "Error report sent 👌",
		REPORT_ERROR: "An error occurred while sending the error report 🤯",
	},
	Table: {
		NAME: "Name",
		TITLE: "Title",
		TEXT: "Text",
		DATE: "Date",
		PUBLISHED_DATE: "Published date",
		AVAILABLE_TO_DATE: "Available to",
		PREVIEW: "Preview",
		DESC: "Description",
		COLOR: "Color",
		TYPE: "Type",
		AUTHOR: "Author",
		NO_CONTENT: "No content to display",
		END_OF_TABLE: "End of the table",
		ADD_COL: "Add column",
		ADD_ROW: "Add row",
		CLEAN: "Empty",
		CLEAN_ROW: "Clear row",
		CLEAN_COL: "Clear column",
		BEFORE: "Before",
		AFTER: "After",
		DELETE: "Delete",
		DELETE_ROW: "Delete row",
		DELETE_COL: "Delete column",
	},
	MaterialTable: {
		body: {
			emptyDataSourceMessage: "No items to display",
			addTooltip: "Add",
			deleteTooltip: "Delete",
			editTooltip: "Edit",
			filterRow: {
				filterTooltip: "Filter",
			},
			editRow: {
				deleteText: "Do you want to delete this line?",
				cancelTooltip: "Cancel",
				saveTooltip: "Save",
			},
		},
		grouping: {
			placeholder: "Slide the header",
			groupedBy: "Group by:",
		},
		header: {
			actions: "Actions",
		},
		pagination: {
			labelDisplayedRows: "{from}-{to} to {count}",
			labelRowsSelect: "lines",
			labelRowsPerPage: "lines per page:",
			firstAriaLabel: "First page",
			firstTooltip: "First page",
			previousAriaLabel: "Previous page",
			previousTooltip: "Previous page",
			nextAriaLabel: "Next page",
			nextTooltip: "Next page",
			lastAriaLabel: "Last page",
			lastTooltip: "Last page",
		},
		toolbar: {
			addRemoveColumns: "Add or delete columns",
			nRowsSelected: "{0} selected line(s)",
			showColumnsTitle: "View columns",
			showColumnsAriaLabel: "View columns",
			exportTitle: "Export",
			exportAriaLabel: "Export",
			exportName: "Export as CSV",
			searchTooltip: "Search",
			searchPlaceholder: "Search",
		},
	},
	Categories: {
		TYPE: "Category type",
		CATEGORIES: "Categories",
		ADD_CATEGORY: "Add category",
		MODIFY_CATEGORY: "Modify category",
		DUP: "Duplicate",
		DUP_CATEGORY: "Copy to type",
		DUP_FOR: "Duplicate for the following modules",
		DUPLICATION: "Categories duplication",
		DUPLICATING: "Duplicating categories",
		"TYPE-ALL": "All categories",
		"TYPE-post-1": "News",
		"TYPE-post-3": "Articles",
		"TYPE-post-4": "Offers",
		"TYPE-post-13": "Alerts",
		"TYPE-post-14": "Pro offers",
		"TYPE-post-5": "Careers",
		"TYPE-post-8": "Events",
		"TYPE-documents": "Documents",
		"TYPE-charts": "Charts",
		ASKDELETE_one:
			'The category "{{name}}" of type "{{type}}", will be deleted. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} categories, will be deleted. $t(Commons.ASK_CONTINUE)",
		Highlight_Categories: "Highlight categories",
		Highlight_Categories_Desc: "Select up to 4 categories per type to highlight on the corresponding pages"
	},
	Groups: {
		GROUPS: "Groups",
		ALL_GROUPS: "All groups",
		SELECT_GROUPS: "Select one or more groups",
		PUBLISH_BY_GROUPS: "Publish by groups",
		ADD_GROUP: "Add group",
		MODIFY_GROUP: "Modify group",
		IDENTITY_STEP: "Identity",
		MEMBERS: "Members",
		USER_IN_GROUP: "user in the group",
		USERS_IN_GROUP: "users in the group",
		NAME_ERROR: "Please enter a name for the group",
		DUP_POSTS:
			"Assign existing posts that are related to the following groups to the new group",
		SEND_GROUP: "Sending group",
		LOADING_USERS: "Loading users",
		NO_USERS: "No user",
		ASKDELETE_one:
			'The group "{{name}}" will be deleted. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} groups will be deleted. $t(Commons.ASK_CONTINUE)",
		GROUP_one: "{{count}} group",
		GROUP_other: "{{count}} groups",
	},
	WorkFamillies: {
		WORK_FAMILLIES: "Famillies",
		ADD_FAM: "Add family",
		MODIFY_FAM: "Modify family",
		SELECT_FAM: "Select a family",
		ALL_FAMS: "All famillies",
	},
	WorkFunctions: {
		WORK_FUNCTIONS: "Functions",
		ADD_FUNC: "Add function",
		MODIFY_FUNC: "Modify function",
		SELECT_FUNC: "Select a function",
		ALL_FUNCTIONS: "All functions",
	},
	Images: {
		IMAGES_LOAD: "Loading images",
		IMAGE_LOAD: "Loading image",
		IMAGE_SEND: "Sending image",
		IMAGES_SEND: "Sending images",
		IMAGE_OVERLAY: "Click to choose an image or drag it here",
		MODIFY_IMAGE_OVERLAY:
			"Click, or drag image here, to modify the current image",
		IMAGE_SIZE_ERROR: "The image size",
		IMAGE_SIZE_ERROR_2: "must not exceed",
		ADD_IMAGE: "Add image",
		RM_IMAGE: "Delete image",
		NO_IMAGE: "No image",
		RECOMMENDED_SIZE: "Recommended size: {{width}} x {{height}} pixels",
	},
	Files: {
		FILE: "File (PDF, 20 Mo max)",
		FILES: "Files",
		FILE_NAME: "Name",
		FILES_LOAD: "Loading files",
		FILE_LOAD: "Loading file",
		FILE_SEND: "Sending file",
		FILES_SEND: "Sending files",
		FILE_PH: "Click to choose a pdf file or drag it here",
		FILE_SIZE_ERROR: "The file size",
		FILE_SIZE_ERROR_2: "must not exceed",
		ADD_FILE: "Add file",
		RM_FILE: "Delete file",
		NO_FILE: "No file to display",
		IMPORT_FROM_PC: "Import from device",
		SELECT_FROM_GALLERY: "Select from gallery",
		SET_NAME: "Please enter a name for the document",
		SELECT_FILE: "Please select a file",
	},
	AppFunctions: {
		FUNCTIONS: "Features",
		MSG: "Disabled features will still be visible to admins",
		COMMENTS: "Posts comments",
		ContactPage: "Contacts",
		CorporatePage_Articles: "About",
		CorporatePage_Charts: "Key numbers",
		CorporatePage_DocsUtils: "Useful documents",
		DocumentsPage_AllowSharing: "Allow document sharing",
		PeoplePage_Agenda: "Agenda",
		PeoplePage_Career: "Careers",
		PeoplePage_PostAd: "Post offers",
		PeoplePage_Rss: "External feed",
		PeoplePage_PostProAd: "Post pro offers",
		PeoplePage_ViewAd: "Offers",
		PeoplePage_ViewProAd: "Pro offers",
		ProfilePage_PersonalDocumentPage_MyAd: "My offers",
		ProfilePage_PersonalDocumentPage_MyNews: "My news",
		ProfilePage_PersonalDocumentPage_PaySlip: "My documents",
		ProfilePage_PersonalDocumentPage_SavedNews: "Saveds posts",
		ProfilePage_PersonalProfilPage_CanChangePassword: "Change password",
		ProfilePage_PersonalProfilPage_CanChangeProfil: "Change profile",
		AnimationsPage_CanParticipate: "Participate in calendar events",
		NewsPage_PostQueueStyle: "News moderation",
		NewsPage_Comments: "$t(AppFunctions.COMMENTS)",
		OffersPage_PostQueueStyle: "Offers moderation",
		ProOffers_PostQueueStyle: "Pro offers moderation",
		SitesPage: "Sites page",
		PostsPage: "Posts",
		PostsPage_CanUseMarkdown:
			"Rich text editor for writing (bold, italic, etc...)",
		Forms: "Surveys",
	},
	Login: {
		EMPTY_FORM: "Please enter a username and password",
		LOGIN_PH: "Username",
		PASS_PH: "Password",
		SAVE_PASS: "Save password",
		CONNECT: "Log in",
		CONNECTING: "Login in ...",
		FORGOTTEN_IDS: "Forgot login or password?",
		BACK_HOME: "Return to the login page",
		FILL_FIELDS: "Please fill in the following fields",
		MAIL: "Email address",
		MAIL_MSG: "Please enter your email address to request new identifiers",
		NAME_PH: "Enter your last name",
		FNAME_PH: "Enter your first name",
		RESET_LOGIN_PH: "Enter your login or email address",
		COMP_PH: "Enter the name of your company or network",
		SEND_OK: "Your request has been processed!",
		SEND_OK2: "If you already have an OnlyAll account, your new login details will be sent to you shortly. ",
		SEND_OK3: "Please note: If you do not yet have an OnlyAll account, you will not receive a login and password. ",
		SEND_OK4: "Please contact one of your administrators to create your account.",
		ERROR_1: "Please enter your last name",
		ERROR_2: "Please enter your first name",
		ERROR_3: "Please enter your username or email address",
		ERROR_4: "Please enter the name of your company or network",
		MAIL_ERROR_1: "No account found with the given email address entered.",

		// ForgetForm
		FORGET_ASK_NEW_MSG: "Request new credentials",
		FORGET_THIS_ACCOUNT_BTN: "Ask for this account",
		FORGET_CHANGE_ACCOUNT_BTN: "Ask for another account",

		//API ERRORS
		invalid_grant:
			"The username or password does not correspond to any account",

		// Server errors
		ECONNABORTED:
			"Request timeout. Please check your internet connection then try again.",
		SERV_ERROR_1: "An error occurred while sending the request.",
		SERV_ERROR_2: "Please check your internet connection then try again.",
		SERV_ERROR_3: "Contact an administrator if the problem persists.",
	},
	Accounts: {
		ADD_ACCOUNT: "Add account",
		DISCONNECT: "Logout",
		DISCONNECTING: "Disconnecting",
	},
	Pages: {
		COMPANIES: "Companies",
		COMPANY: "Company",
		APPEARANCE: "Appearance",
		HOME: "Home",
		MY_SPACE: "My OnlyAll",
		MY_SETTINGS: "My settings",
		CORPORATE: "Corporate",
		PEOPLES: "People",
		AGENDA: "Agenda",
		ARTICLES: "About",
		CAREERS: "Careers",
		CHARTS: "Indicators",
		CONTACTS: "Contacts",
		DOCUMENTS: "Documents",
		FORMS: "Surveys",
		NEWS: "News",
		OFFERS: "Offers",
		PRO_OFFERS: "Pro Offers",
		ALERTS: "Alerts",
		CONFIGURATION: "Configuration",
		SITES: "Sites",
		PROFESSIONS: "Professions",
		GROUPS: "Groups",
		CATEGORIES: "Categories",
		ANALYTICS: "Analytics",
		REACTIONS: "Reactions",
		PROFILE: "Profile",
		RSS: "Rss",
	},
	Theme: {
		DARK_THEME: "Dark theme",
		LIGHT_THEME: "Light theme",
	},
	Settings: {
		LANGUAGE: "Language",
		BUTTONS_STYLE: "Buttons styles",
		MENU_STYLE: "Menu style",
		CUSTOM_COLOR: "Interface color",
		DEFAULT: "Default",
		RESET: "Reset",
		CLASSIC: "Classic",
		ROUNDED: "Rounded",
		EXAMPLE: "Example",
		BUBBLES: "Bubbles",
		FEATURES: "Features",
		ADVANCED_MD: "Advanced Markdown editor",
		DEFAULT_LINK: "Default post targeting",
		BY_SITES: "By sites",
		BY_GROUPS: "By groups",
	},
	Companies: {
		COMPANIES: "Companies",
		NAME: "Name",
		ALL_COMPANIES: "All companies",
		ADD_NETWORK: "Add a network",
	},
	Sites: {
		SITES: "Sites",
		FAV_SITES: "Favorites sites",
		ALL_SITES: "All sites",
		SELECT_SITES: "Select one or more sites",
		SELECT_SITE: "Select a site",
		ADD_BTN: "Add site",
		ADD_TITLE: "Add site",
		MODIFY_TITLE: "Modify site",
		NO_SITE_FOUND: "No site found",
		CONTACT_STEP: "Contact",
		LOCATION_STEP: "Location",
		INPUT_LOCATIOn: "Enter a location",
		NAME: "Name",
		MEMBER: "member",
		MEMBERS: "members",
		MEMBERS_one: "{{count}} member",
		MEMBERS_other: "{{count}} members",
		ADDRESS: "Address",
		TOWN: "Town",
		COUNTRY: "Country",
		POSTAL_CODE: "Postal code",
		LANGUAGE: "Language",
		LANG_MSG: "Select a language for the site",
		NAME_ERROR: "Please enter a site name",
		COUNTRY_ERROR: "Please enter a country for the site",
		ADRESS_ERROR:
			"No address found, please check the information to be able to display the site on the map",
		DUP_POSTS:
			"Duplicate online publications for all sites on the new site",
		SEND_SITE: "Sending site",

		ASKDELETE_one:
			"The site {{name}}and all of its users will be deleted. $t(Commons.ASK_CONTINUE)",
		ASKDELETE_other:
			"{{count}} sites and all their users will be deleted. $t(Commons.ASK_CONTINUE)",
	},
	Appearance: {
		LOGO_COLORS: "Logo and colors",
		LOAD_IMAGES: "Loading images",
		DEFAULT_IMAGES: "Default images",
		GALLERY: "Image gallery",
		APP_FUNCTIONS: "Features",
		MENUS: "Menus & pages",
		LOGO: "Logo",
		LOGO_DESC: "Logo of your company or network",
		LOGO_DARK_THEME: "Logo for dark theme",
		LOGO_DARK_THEME_DESC: "Logo for dark theme (optional)",
		APP_ICON: "Web application icon (WIP)",
		COLORS: "Colors",
		MAIN_COLOR: "Main color",
		SECONDARY_COLOR: "Secondary",
		CUSTOM_COLOR_WARN:
			"A custom interface color has been defined in your settings",
		WEB_LOAD: "Web",
		MOBILE_LOAD: "Mobile",
		BANNER: "Banner",
		CUSTOM_TITLES: "Menus and pages",
		CUSTOM_TITLES_MSG:
			"Custom titles for pages other than 'News', 'Company', 'People' and 'Contacts' are not yet available", // for the mobile app
		ADD_CUSTOM_TITLE: "Add custom title",
		DEFAULT_VALUES: "Reset default values",
		REMOVE_VALUES: "Delete custom values",
		PRINCIPAL_COLOR: "Main color (optional)"
	},
	Assignator: {
		TARGET: "Target",
		NO_TYPE_SELECTED_ERROR: "Please select a target type",
		NO_TARGET: "Please select one or more targets",
		NEW_TARGET: "New target",
		ADD_FILTER: "Filter",
	},
	Posts: {
		LOADING: "Loading posts",
		LOAD_ERROR: "An error occured while loading posts",
		LIST_END: "Posts feed end",
		NO_RESULT: "No post matches the search",
		ADD_BTN: "Write post",
		MODERATED: "Moderated",
		// Post preview
		MODERATION_MSG: "Moderation comment",
		MOD_REASON: "Please enter the reason for the rejection",
		READ_MORE: "Read more",
		VIEWS: "views",
		VIEWS_one: "view",
		VIEWS_other: "views",
		LIKES: "likes",
		LIKES_one: "like",
		LIKES_other: "likes",
		NO_LIKES: "No one liked the post",
		REACTIONS: "Reactions",
		REACTIONS_zero: "No one reacted to the post",
		REACTIONS_one: "Reaction",
		REACTIONS_other: "Reactions",
		FAVORITE: "Favorite",
		SAVE_POST: "Save post in favorites",
		SAVED_POST: "Post saved in favorites",
		DISPLAY_DOCS: "Display linkeds documents",
		DOCS_BTN_one: "Document",
		DOCS_BTN_other: "Documents",
		CLOSE_DOCS: "Close documents drawer",
		EXTEND_POST: "Extend post",
		REDUCE_POST: "Minimize post",
		EXTEND_IMAGES: "Extend images",
		PREV_POST: "Previous post",
		NEXT_POST: "Next post",
		// Edit drawer
		ADD_TITLE: "Write post",
		MODIFY_TITLE: "Modify post",
		EDIT_TITLE: "Edit post",
		PUBLISH_TITLE: "Publish post",
		// steps
		CONTENT_STEP: "Content",
		POST_RESPONSE: "Additional content",
		IMAGES_STEP: "Images",
		ATTACH_STEP: "Attachments",
		PREVIEW_STEP: "Preview",
		SEND_STEP: "Send",

		POST_SUBTITLE: "Post sub-title",
		POST_TITLE: "Post title",
		POST_TEXT: "Post content",
		SUBTITLE_ERROR: "Please enter a post sub-title",
		TITLE_ERROR: "Please enter a post title",
		TEXT_ERROR: "Please enter a post content",
		DELETE_COMPLEMENT: "Delete additional content",
		DELETE_COMPLEMENT_MSG:
			"Are you sure you want to delete the additional content?",
		DELETE_COMPLEMENT_MSG1: "(irreversible change)",
		DELETING_COMPLEMENT: "Deleting the additional content",

		//delete
		ASKDELETE_one:
			'The post "{{title}}" will be deleted. $t(Commons.ASK_CONTINUE)',
		ASKDELETE_other:
			"{{count}} posts will be deleted. $t(Commons.ASK_CONTINUE)",
		DELETING_one: "Deleting post",
		DELETING_other: "Deleting posts",

		//send
		SEND_POST: "Sending post",
		SEND_POST_RESP: "Sending post additional content",
		SEND_IMAGES: "Sending images",
		SENDING_ATTACHMENTS: "Sending attachments",
		POST_PUBLISHED: "Your post is online!",
		POST_DIFFERED: "Your post will be online the",
		POST_DRAFTED: "Post saved as draft",
		POST_MODERATION: "Your post has been sended !",
		POST_MODERATION_2:
			"It will be moderated and published by an administrator very soon",
		//States
		WAITING_BTN: "Waiting moderation",
		WAITING_MODIF_BTN: "Waiting modifications",
		PUBLISHED_BTN: "Published",
		DRAFT_BTN: "Drafts",
		// Comments
		COMMENTS: "Comments",
		LOADING_COMMENTS: "Loading comments",
		SHOW_COMMENTS: "Show comments",
		CLOSE_COMMENTS: "Close comments",
		NO_COMMENT: "Not any comment",
		REPLY: "Reply",
		CAREER_APPLY: "Apply",
		COMMENT_PH: "Enter your answer",
		RESP_SEND: "Send",
		RESP_SENDED: "Your response has been sent!",
		NEW: "New",

		// Stats
		POST_STATS: "Statistics",
		CLOSE_STATS: "Close post statistics",
		LAST_CHANGED: "Last changed date",
		PUBLISHED_DATE: "Published date",
		REACHED_USERS: "Users reached",
		READED_zero: "No user has read the post",
		READED_one: "{{count}} user read the post",
		READED_other: "{{count}} users read the post",
		CREATED_FROM: "Created from",
		ORIGIN: "Original location",

		//Alerts
		SELECTED_TARGETS_zero: "No user selected",
		SELECTED_TARGETS_one: "{{count}} user selected",
		SELECTED_TARGETS_other: "{{count}} users selected",
	},
	Comments: {
		DELETE_ERROR: "Delete error",
		REPORT_COMMENT: "Not available at the moment - Report the comment",
		DELETE_COMMENT: "Delete comment",
		MODIFIED: "edited",
	},
	EditDrawer: {
		PREV_BTN: "Previous",
		NEXT_BTN: "Next",
		DRAFT_BTN: "Save as draft",
		SAVE_BTN: "Save changes",
		SAVE_NO_PUBLISH_BTN: "Save without publishing",
		VALIDATE_BTN: "Save",
		END_BTN: "Finish",
		RETRY_BTN: "Retry",
		SEND_BTN: "Send",
		SEND_STEP: "Sending",
		END_STEP: "Close",
		PREVIEW_STEP: "Preview",
		SENDED_WARN: "A non-blocking error occurred while sending",
		// SENDED_WARN: "Sending partially successful. Click the \"Retry\" button to try again"
	},
	Gallery: {
		IMAGES_ROW_TITLE: "Post images",
		GALLERY_TITLE: "Gallery",
		LOADING: "Gallery loading ...",
		LOAD_ERROR: "An error occured while loading the gallery",
		NO_IMAGE: "No image available",
		DROP_MSG:
			"Drag the images into the block below to add them to the gallery",
	},
	MDE: {
		PH: "Please enter your text",
		HEADER_BTN: "Header",
	},
	Events: {
		LOADING: "Loading events",
		LOAD_ERROR: "An error occured while loading events",
		LOAD_ERROR_2:
			"An error occurred while retrieving the post linked to the event",
		SHOW: "Show the event",
		MODIFY: "Modify the event",
		EXPORT: "Export in my calendar",
		PARTICIPATION: "Participation",
		ENABLE_PARTICIPATION: "Allow users to participate",
		PARTICIPATE: "Participate in the event",
		NO_PARTICIPATE: "Stop participating in the event",
		PARTICIPANT_zero: "No participant",
		PARTICIPANT_one: "{{count}} participant",
		PARTICIPANT_other: "{{count}} participants",
		DELETING: "Deleting event",
		YEAR: "Year",
		MONTH: "Month",
		WEEK: "Week",
		GRID: "Grid",
		LIST: "List",
		NO_EVENT: "No event for the selected day",
		NO_UPCOMING_EVENT: "No upcoming event",
		TILE_DATE_FORMAT: "MM/DD/YYYY",
		DATE_INPUT_FORMAT: "MM/dd/yyyy",
		DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm",
		DATE_TIME_AT_FORMAT: "MM/DD/YYYY [at] HH:mm",
		DATE_TIME_INPUT_FORMAT: "MM/dd/yyyy HH:mm ",
		COMING_EVENTS: "Coming events",
		ADD_TITLE: "Add event",
		MODIFY_TITLE: "Modify event",
		EVENT_TITLE: "Event title",
		EVENT_DESC: "Description",
		TITLE_ERROR: "Please enter a title for the event",
		SENDING_EVENT: "Sending event",
		DATES: "Dates",
		START_DATE: "Start date",
		END_DATE: "End date",
		START: "Start",
		END: "End",
		ALL_DAY: "All day",
		FULL_DAY: "Full day",
		THE_EVENT: "The event",
		WILL_BE_DELETE: "will be deleted, do you want to continue?",
	},
	Docs: {
		LOADING: "Documents loading",
		LOAD_ERROR: "An error occured while loading documents",
		LOAD_DOC_ERROR: "An error occured while loading the document",
		LIST_END: "No more document to display",
		NO_RESULT: "No document found",
		NO_DOC: "No document to display",
		DOC_LOAD: "Document loading",
		FAVORITES: "Favorites docs",
		SENDING_DOC: "Sending document",
		SENDING_DOCS: "Sending documents",
		NO_FAV: "No favorites documents",
		DEL_DOC_TITLE: "Delete document",
		DELETING_one: "Deleting document",
		DELETING_other: "Deleting documents",
		LINKED_DOCS_one: "{{count}} Linked Document",
		LINKED_DOCS_other: "{{count}} Linked Documents",
		EXISTINGS_DOCS: "Existing documents",
		IMPORTED_DOCS: "Imported document",
		ADD_DOC: "Add document",
		ADD_TO_DOCS_UTIL: "Add to company docs",
		PREVIEW_SIZE_ERROR: "Document size is too large to display preview",
		MULTI_RM_MSG: "3 documents will be deleted, do you want to continue?",
		THE_DOC: "The document",
		WILL_BE_DELETE: "will be deleted, do you want to continue?",
		SENDED: "Document sended",
		ALLOW_SHARE: "Allow document sharing",
		SHARED_DOC: "Shared document",
		SHARE_DOC: "Share document",
	},
	Charts: {
		ADD_CHART: "Add chart",
		LOADING: "Loading charts",
		LOAD_ERROR: "An error occured while loading charts",
		LIST_END: "No more chart to display",
		NO_CHART: "No chart to display",
		TYPE_STEP: "Chart type",
		VALUES_STEP: "Values",
		SENDING: "Sending chart",
		CHART_SENDED: "The indicator has been published",
		CHART_DRAFTED: "The indicator has been saved as draft",
		CTX_MENU_MSG: "Use your right click on cells for more actions",
		TYPE: "Type",
		TITLE: "Title",
		OPTIONS: "Options",
		PREVIEW: "Preview",
		VALUES: "Values",
		bars: "Bars",
		bars_groupName: "Bars",
		columns: "Columns",
		columns_100: "Stacked columns",
		rounded: "Rounded",
		rounded_area: "Rounded area",
		stacked_area: "Stacked aera",
		area: "Area",
		stacked_area_100: "Stacked area",
		pie: "Pie",
		round: "Donut",
		round_gauge: "Round gauge",
		gauges_and_text: "Gauges and text",
		gauge: "Gauge",
		text: "Text",
		NO_TITLE_ERROR: "Please enter a title for the indicator",
		NO_VALUE_ERROR: "Please enter values for the indicator",
		ASKDELETE_one:
			'The chart "{{title}}" will be deleted, do you want to continue?',
		ASKDELETE_other:
			"{{count}} charts will be deleted, do you want to continue?",
		DELETING_one: "Deleting chart",
		DELETING_other: "Deleting charts",
	},
	Contacts: {
		ADD_BTN: "Add user",
		MODIFY_TITLE: "Modify user",
		LOADING: "Loading contacts",
		HANDLE_USERS: "Users management",
		EXPORT_USERS: "Export users",
		IMPORT_USERS: "Import users (soon)",
		FILTERS_APPLIES: "Filters are applied to the list of users",
		SELECTABLE_USERS: "selectable users",
		BELONGING_SITE: "Belonging to the site",
		PART_OF: "Part of the groups",
		SEARCH_RESULTS: "Search results",
		FAVORITES_USERS: "Among favourite users",
		ACTIVE: "Actives",
		INACTIVE: "Inactives",
		ALL_USERS: "All",
		ACTIVE_USERS: "Only active users",
		INACTIVE_USERS: "Only inactive users",
		USERS_STATUS: "Users status",
		SOON: "Coming soon",
		LOAD_ERROR: "An error occured while loading contacts",
		LIST_END: "No more contact to display",
		HIDDEN_INFOS: "User has hidden their contact information",
		PHONE: "Phone",
		MAIL: "Mail",
		WEBSITE: "Website",
		MAIN_CONTACT: "Main contact",
		MAIN_CONTACT_PH: "Select an admin",
		MAIN_CONTACT_MSG:
			'The contact will be linked to the "Contact an administrator" button of the mobile application',
		ERROR_LASTNAME: "Please enter a lastname",
		ERROR_FIRSTNAME: "Please enter a firstname",
		ERROR_SITE: "Please select a site",
		ERROR_FAMILY: "Please select a family",
		ERROR_FUNCTION: "Please select a function",
		ERROR_NO_CONTACT: "Please enter a phone number or an email",
		ERROR_MAIL:
			"The format of the email is incorrect, please check your entry",
		OLD_ERROR_PHONE:
			"The phone format is incorrect, please enter only numbers without spaces",
		ERROR_PHONE: "The phone format is incorrect, please check your entry",
		ERROR_WEBSITE:
			"The format of the site url is incorrect, please check your entry",
		ERROR_ROLE: "Please select a role",
		IDENTITY_STEP: "Identity",
		RIGHTS_STEP: "Role and rights",
		DOCS_STEP: "Personal documents",
		USER_ROLE_TXT: "User role",
		READ_RIGHT: "Read",
		WRITE_RIGHT: "Create",
		MODIFY_RIGHT: "Modify",
		ADMIN_RIGHT: "Administrate",
		READER_ROLE: "Reader",
		USER_ROLE: "User",
		ADMIN_ROLE: "Administrator",
		SUPER_ADMIN_ROLE: "Super administrator",
		ELOADMIN: "JoonDev (EloAdmin)",
		ONLINE: "Online",
		RESET_PASS: "Reset password",
		RESET_PASS_MSG:
			"Are you sure you want to generate a new password for the user",
		RESET_PASS_MSG2:
			"His new login credentials will be sent to him by email",
		EXPORT: "Export",
		EXPORT_TITLE: "Export users",
		EXPORT_FILEDS_MSG: "Select fields to export",
		SELECTED_one: "{{count}} user selected",
		SELECTED_other: "{{count}} users selected",
		NO_SELECTED_ERROR: "Please select users to export",
		NO_FIELDS_ERROR: "Please select fields to export",
		SELECTED_USERS: "selected users",
		ASKDELETE_one:
			'User "{{name}}" will be deleted, do you want to continue?',
		ASKDELETE_other:
			"{{count}} users will be deleted, do you want to continue?",
		DELETING_one: "Deleting user",
		DELETING_other: "Deleting users",
		FAVORITE: "Favorite contacts",
		NO_FAVORITE: "No favorite contacts",
	},
	Rss: {
		RSS_POPUP_TITLE: "New Feeds",
		ADD_FEED: "Add feeds",
		RSS_ADMIN: "External Feed",
		ASKDELETE_one:
			'Rss "{{name}}" will be deleted, do you want to continue?',
		ASKDELETE_other:
			"{{count}} rss feeds will be deleted, do you want to continue?",
		DELETING_one: "Deleting rss feed",
		DELETING_other: "Deleting rss feeds",
		SELECT_RSS_FEEDS: "Select rss feeds",
		SEARCH_RSS_FEEDS: "Enter a feed url",
		NOTIFY: "notify new updates to the feed",
		RSS_NAME: "Feed name",
		RSS_INSPIRATION: "Inspiration",
		RSS_GO_BACK: "Go back",
		SUBMIT: "Submit",
		NAME: "Name",
		IS_NOTIFIED: "Notif",
		INSPIRED_BY_RSS: "Inspired by this news",
		IMAGE_TITLE: "Image added to this news",
		ERROR_MSG: "No feeds have been found",
		FULL_POST: "Click here to access to the full post",
		EMPTY_ITEMS: "No feeds to display",
	},
	MyOnlyAll: {
		PROFILE_BTN: "My profile",
		NEWS_BTN: "My news",
		OFFERS_BTN: "My offers",
		PRO_OFFERS_BTN: "My pro offers",
		CAREERS_BTN: "My careers",
		DOCS_BTN: "My documents",
		PROFILE_ERROR: "Profile loading error",
	},
	User: {
		USER: "User",
		FIRST_NAME: "First name",
		LAST_NAME: "Last name",
		MAIL: "Email",
		PHONE: "Phone",
		IMAGE: "Profile picture(optional)",
		VISIBLE: "Show",
		LOGIN: "Login",
		COMPANY: "Company",
		COMPANY_NETWORK: "Company / Network",
		SITE: "Site",
		GROUPS: "Groups",
		ROLE: "Role",
		WORK_FAM: "Work familly",
		WORK_FUNC: "Work function",
		HOBBY: "Hobby",
		SAVE_BTN: "Save infos",
		SENDING_BTN: "Sending infos...",
		LAST_CONN: "Last connection",
		NO_CONN: "never",
		SELECT_FILE: "Select a file",
	},
	Password: {
		DISPLAY: "Display password",
		OLD_PASS: "Old password",
		NEW_PASS: "New password",
		CONFIRM_NEW_PASS: "Confirm new password",
		EMPTY_OLD_ERROR: "Enter your old password",
		OLD_ERROR: "The old password is incorrect",
		NOT_VALID_ERROR: "The new password must respect the folowing rules:",
		LENGTH_ERROR: "- At least 8 characters",
		CHARS_ERROR: "- Must contain upper and lower case",
		SPEC_CHARS_ERROR:
			"- Must contain atleast a number and a special character ( ,;#.!@$%^&*- )",
		NO_MATCH_ERROR: "New password and confirmation do not match",
		SAVE_PASS: "Change password",
	},
	ImagesEditor: {
		APPLY: "Apply the OnlyAll recommendation",
		LOAD: "Load",
		CANCEL: "Keep my original framing (the image may not be displayed correctly)",
		HAND: "Move",
		CROP: "Crop",
		FLIP: "Flip",
		ROTATE: "Rotate",
		DRAW: "Draw",
		SHAPE: "Add shape",
		TEXT: "Add text",
		ZOOM_IN: "Zoom In",
		ZOOM_OUT: "Zoom Out",
		HISTORY: "History",
		UNDO: "Undo",
		REDO: "Redo",
		RESET: "Reset origin image",
		DELETE: "Remove",
		DELETE_ALL: "Remove all changes",
		CROP_TOOLTIP: "Crop image",
		CANCEL_EDITOR: "Cancel",
		CANCEL_TOOLTIP: "Cancel image edit",
		SAVE: "Save",
		SAVE_TOOLTIP: "Save image",
		HELP: "How to use the image editor",
	},
	Weather: {
		WEATHER: "Weather",
		ERROR: "Weather loading error",
		LOAD: "Weather loading",
		SUNRISE: "Sunrise",
		SUNSET: "Sunset",
		HUMIDITY: "Humidity",
		WIND: "Wind",
		PRESSURE: "Pressure",
		FEEL: "Feel",
		PROMPT_CITY:
			"Enter a city name or an address below to display the weather forecast",
	},
	Welcome: {
		WELCOME: "Welcome {{FirstName}}",
		DELIGHTED: "Delighted {{FirstName}}",
		NTMY: "Nice to meet you {{FirstName}}",

		MORNING: "Good morning {{FirstName}}",
		AFTERNOON: "Good afternoon {{FirstName}}",
		EVENING: "Good evening {{FirstName}}",
		NIGHT: "Good night {{FirstName}}",

		MENU_WELCOME: "Welcome,",
		MENU_MORNING: "Good morning,",
		MENU_AFTERNOON: "Good afternoon,",
		MENU_EVENING: "Good evening,",
		MENU_NIGHT: "Good night,",

		FORGOT: "Did you forget something {{FirstName}}?",
		ALREADY_BACK: "Already back {{FirstName}}?",
		MISSING_YOU: "Do you already miss me {{FirstName}}?",

		GO: "Immediate boarding ! 🚀",
		SEATBELT: "Fasten your seat belt {{FirstName}} ! 🚀",
		FORCE: "May the force be with you {{FirstName}} ",
		LOVE: "I love you too {{FirstName}} 💛",
		STRETCH: "Remember to stretch from time to time {{FirstName}}",
		GOOD_DAY: "Is your day going well {{FirstName}}?",

		INSOMNIA: "Don't you sleep {{FirstName}}?",
		GOOD_SLEEP: "Did you sleep well {{FirstName}}?",
		MORNING_DRINK: "Rather tea or coffee {{FirstName}}?",
		BG: "You look good {{FirstName}} !",
		EAT: "Take time to eat {{FirstName}}",
		HAPPY_MEAL: "Bon appétit {{FirstName}} !",

		WELCOME_BACK: "Welcome back {{FirstName}} !",
		EVOLVED: "I have improved a lot since your last visit {{FirstName}} !",
		MISSING: "I never thought I'd see you again {{FirstName}}",
		MISSING_WOW: "{{FirstName}}, it's really you?!",
	},
	Forms: {
		ADD_FORM: "New survey",
		ANSWER_FORM: "Answer survey",
		EDIT_FORM: "Edit survey",
		LOADING_FORM: "Loading survey",
		EXPORT_ANSWERS: "Export answers",
		FORM_SELECTION: "Surveys selection",
		LINKED_FORMS_one: "{{count}} Linked survey",
		LINKED_FORMS_other: "{{count}} Linked surveys",
		AVAILABLE_TO_DATE: "Available until {{date}}",
		FORM_TITLE: "Survey title",
		SECTION_TITLE: "Section title",
		DESC: "Description (optional)",
		QUEST_TITLE: "Question title",
		QUEST_INFOS: "Additional Information (optional)",
		QUEST_TYPES: "Question types",
		ADD_SECTION: "Add survey section",
		ADD_QUESTION: "Add question to current section",
		ADD_OPTION: "Add answer option",
		CLONE_QUEST: "Clone question",
		RM_SECTION: "Remove section",
		RM_QUEST: "Remove question",
		REQUIERED: "Required",
		REQUIERED_TXT: "Make question required",
		REQUIERED_ERR:
			'The question "{{title}}" is requiered, please answer it',
		SHORT_TEXT: "Short text",
		SHORT_TEXT_EX: "Short text exemple",
		LONG_TEXT: "Long text",
		LONG_TEXT_EX: "Long text exemple",
		UNIQUE_CHOICES: "Unique choice",
		MULTIPLE_CHOICE: "Multiple choice",
		RANGE_INPUT: "Range / Notation",
		RANGE_MIN: "Minimum",
		RANGE_MAX: "Maximum",
		ENTER_ANSWER: "Enter your answer",
		YOUR_ANSWER: "Your answer",
		FORM_NO_SECTION_ERROR: "Please add a section to the survey",
		FORM_EMPTY_TITLE: "Please enter a title for the survey",
		FORM_EMPTY_SECTION_TITLE: "Please enter a title for the section",
		FORM_NO_SECTION_QUESTION:
			"Please add at least one question for the section",
		FORM_QUESTION_EMPTY_TITLE: "Please enter a title for the question",
		FORM_SELECT_VALUE: "Please select a value",
		FORM_MULTIPLE_ANSWERS_MISSING_OPTION:
			"The question has no answer options",
		FORM_ANSWER_MISSING_VALUE: "The answer option has no value",
		FORM_ANSWER_OUT_OF_RANGE:
			"The selected value is not between {{min} and {{max}}",
		ANON_ANSWERS: "Anonymous answers",
		CHANGE_ANON_ERROR_MSG:
			"This option cannot be modified because the survey answers are already anonymous",
		DEFFERED_PUBLISH: "Deferred publication",
		ANS_LIMIT_DATE: "Reply deadline",
		LIMIT_DATE_ERROR: "Reply deadline is earlier than post date",
		SENDING_FORM: "Sending the survey",
		FORM_DRAFTED: "The survey has been saved as a draft",
		FORM_SENDED: "The survey has been sent",
		VIEW: "Visualization",
		BARS_VIEW: "Bars",
		PIE_VIEW: "Parts",
		TABLE_VIEW: "Table",
		SECTION_INDEX: "Section {{index}}",
		QUESTIONS: "Questions",
		QUESTIONS_one: "{{count}} Question",
		QUESTIONS_other: "{{count}} Questions",
		OPTION: "Option",
		ANSWERS: "Answers",
		ANSWERS_one: "{{count}} Answer",
		ANSWERS_other: "{{count}} Answers",
		ALL_ANSWERS: "All answers",
		NO_ANSWER: "No user answered the question",
		USER: "User",
		DISP_PER_USER: "Show results by user",
		NO_USER_ANS: "The user did not answer",
		LOWEST_ANS: "Lowest answer",
		MEDIAN_ANS: "Average answers value",
		HIGHEST_ANS: "Highest answer",
		ANON_ANSWERS_WARN: "Your answers are anonymous",
		NON_ANON_ANSWERS_WARN: "Your answers are NOT anonymous",
		ASKDELETE_one:
			'Survey "{{title}}" will be deleted, do you want to continue?',
		ASKDELETE_other:
			"{{count}} surveys will be deleted, do you want to continue?",
		DELETING_one: "Deleting survey",
		DELETING_other: "Deleting surveys",
		NO_FORM: "No survey"
	},
	Reactions: {
		ADD_RTYPE: "Add reaction type",
		MODIFY_RTYPE: "Modify reaction type",
		SEND_RTYPE: "Sending Reaction Type",
		DEFINE_DEFAULT: "Set default reactions for each post type here",
	},
	Analytics: {
		SINCE_DEPLOY: "Since deploy",
		FIRST_CONN: "Activated accounts",
		TOTAL_CONN: "Number of connexions",
		NEW_POSTS: "New posts",
		DATE_START: "Start date",
		DATE_END: "End date",
		HOT_POSTS: "Popular Posts",
		POSTS: "posts",
		NO_POSTS: "No posts",
		NO_USERS: "No users",
		LAST_CONN: "Last connections",
		ACTIVATED_ACCOUNTS: "Activated Accounts",
		ACTIVATIONS_BTN: "Activations",
		BTN_CONNECTIONS: "Connections",
		ONLINE: "Online",
		ALL: "All",
		MONTH: "Month",
		WEEK: "Week",
		DAY: "Day",
		AND: "And",
		others_one: "$t(Analytics.AND) {{count}} other",
		others_other: "$t(Analytics.AND) {{count}} others",
		postscount_one: "{{count}} publication",
		postscount_other: "{{count}} publications",

		userscount_activated_one: "{{count}} activation",
		userscount_activated_other: "{{count}} activations",
		userscount_connection_one: "{{count}} connection",
		userscount_connection_other: "{{count}} connections",
	},
	Alerts: {
		New_Alert: "New alert",
	},
	'section "Réglages': {
		Dev_Function: "Developer features",
		Display_Dev_Function: "Display under development features",
		Switch_Company: "Changing company OnlyAll",
		Enable_Tutorials: "Tutorials",
		Enable_Reactions: "Reactions",
		Enable_Highlighted_categories: "Catégories mises en avant",
	},
	Indicators_creation: {
		Indicator_Type: "Select an indicator type",
		Indicator_Type_Bar: "Bars",
		Indicator_Type_Areas: "Areas",
		Indicator_Type_Rounding: "Rounding",
		Indicator_Type_Gauges_text: "Gauges & text",
		Indicator_Column_Title: "Column titles",
		Indicator_Value_Unit: "Value unit",
		Incicator_Column: "column 1",
		Indicator_column_2: "Column 2",
		Indicator_Value: "Value",
		Indicator_Donut_Thickness: "Thickness (%)",
		Indicator_Gauge_Secondary_Thickness: "Secondary thickness (%)",
		Indicator_Gauge_Text_Unit: "Unit",
		Indicator_Gauge_Text_Color: "Color",
		Indicator_Gauge_Text_Secondary_Color: "Secondary color",
	},
	Contact_Page: {
		Contact_Manage_User: "Manage users",
	},
	Modify_User_Page: {
		Modify_User_Personal_documents: "Personal documents",
		Modify_User_Import_Document: "Upload a document",
	},
	Statistic_Page: {
		Statistic_Origins: "Origin of publications",
		Statistic_Type: "Publication Types",
		Platform: "Plateforme",
		Origin: "Origin",
		Sort: "Sort by",
		Publication: "Publications"
	},
	MyOnlyAll_Page: {
		MyOnlyAll_Password_No_Change:
			"The password cannot be changed because you are not logged in with this account.",
	},
	ErrorMsg: {
		Target: "Please select one or more targets",
		Chart: "Please select a type of indicator",
		FormError1: "Error retrieving form",
		QueryError1: "No send queries provided",
		GroupError1: "Error loading groups"
	},
	SuccessMsg: {
		FormFilled: "Your answers have been registered.",
		FormMsg2: "Thank you for your participation!"
	},
	PWAMsg: {
		Message: "By clicking on the button you will install the application on your mobile phone ",
		Title: "Install",
		btnInstall: "Install",
		btnCancel: "Cancel",
		ClickThe: "Click the",
		Icon: "icon",
		ScrollDown: "Scroll down and then click",
		AddToHomeScreen: "Add to Home Screen",
		AlreadyInstalled: "I've already installed",
		DontShowItAgin: "Don't Show it again"
	}
};
