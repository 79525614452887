import useAccount from "classes/Accounts/hooks/useAccount";
import Button from "components/Button/Button";
import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import ScrollTable /*, { AsyncScrollTable }*/ from "components/ScrollTable/ScrollTable";
import download_file, { gen_csv } from "includes/dl_file";
import formatDate from "includes/formatDate";
import { t } from "i18next";
import React, { useState } from "react";
import { getUserProps } from "../../UserProps";
import "./UsersExporter.css";
import { useQueryClient } from "react-query";
import genRequest from "includes/request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
// import genRequest from "includes/request";

export default function UsersExporter(props) {
	const { account } = useAccount();
	// eslint-disable-next-line
	const [Selected] = useState([]);
	// const {account} = useAccount();
	const [Cols, setCols] = useState(getUserProps());
	const [Error, setError] = useState([]);
	const old_selected = React.useRef();
	const QCL = useQueryClient();
	const [IsExporting, setIsExporting] = useState(false);

	// React.useEffect(() => {
	// 	// if (this.props.selected === 0 && (old_selected.current?.length !== this.props.selected))
	// 	// 	this.props.setS
	// 	old_selected.current = props?.selected;
	// }, []);

	// const handleSelect = (values) => {
	// 	if (props.setSelected)
	// 		props.setSelected(values);
	// 	else
	// 		setSelected(values);
	// };

	const getSelected = () => {
		if (props.selected?.length) return props.selected;
		let table_select = QCL.getQueryData(["SelectedEmployes"]);
		if (table_select?.length) return table_select;
		if (props.users?.length) return props.users;
		return Selected;
	};

	const fetchDatas = () => {
		let proms = [
			genRequest(
				"WorkFamillies/WorkFamillyOfCompany/" + account.CompanyId
			),
			genRequest(
				"WorkFunctions/WorkFunctionOfCompany/" + account.CompanyId
			),
			genRequest("Sites/SitesOfCompany/" + account.CompanyId),
		];
		let prom = Promise.allSettled(proms).then((resp) => ({
			Famillies: resp[0]?.value,
			Functions: resp[1]?.value,
			Sites: resp[2]?.value,
		}));
		return prom;
	};

	const treat_list = (usersList, datas) => {
		let ret = usersList.map((u) => {
			let user = { ...u };
			let fam = datas.Famillies.find(
				(fam) => fam.WorkFamillyId === u.WorkFamillyId
			);
			let func = datas.Functions.find(
				(func) => func.WorkFunctionId === u.WorkFunctionId
			);
			let site = datas.Sites.find((site) => site.SiteId === u.SiteId);

			user.WFamName = fam?.WorkFamillyName || t("Commons.ERROR");
			user.WFuncName = func?.WorkFunctionName || t("Commons.ERROR");
			user.SiteName = site?.Name || t("Commons.ERROR");
			return user;
		});
		return ret;
	};

	const export_selected = () => {
		let list = getSelected();
		let headers = Cols.map((a) => a.id);

		if (!list?.length) return setError(t("Contacts.NO_SELECTED_ERROR"));
		if (!headers?.length) return setError(t("Contacts.NO_FIELDS_ERROR"));
		setError(false);
		setIsExporting(true);
		fetchDatas().then(
			(resp) => {
				const universalBOM = "\uFEFF";
				let treated = treat_list(list, resp);
				let csv = gen_csv(headers, treated);
				let name = "Export contacts -" + formatDate(null, "D-M-Y-h-m");
				setIsExporting(false);
				download_file(
					universalBOM + csv,
					name,
					"text/csv;charset=utf-8;"
				);
			},
			() => {
				setIsExporting(false);
			}
		);
	};

	// let Component = AsyncScrollTable;
	// if (props?.selected || props.users)
	// 	Component = ScrollTable;

	// let items = props?.selected || props.users;
	let selected = getSelected();
	return (
		<div
			className={
				"UsersExporter d-flex flex-column overflow-hidden gap-2 h-100" +
				(props.className ? " " + props.className : "")
			}
		>
			{/* <details className="users-list-details" open>
				<summary>
					<h5 className="d-inline">{selected?.length} {t("Contacts.SELECTED_USERS")}</h5>
				</summary>
				<Component
					className={props.tableClassName}
					queryKey={["Employes", "EmployeOfCompany", account.CompanyId]}
					queryFn={() => genRequest("Employes/EmployeOfCompany/" + account.CompanyId)}
					cols={[
						{
							title: t("User.FIRST_NAME"),
							name: "FirstName",
							classes: "post-title-cell"
						},
						{
							title: t("User.LAST_NAME"),
							name: "LastName",
							classes: "post-title-cell"
						}
					]}
					sortBy="FirstName"
					itemId="EmployesId"
					items={[...items]}
					setSelected={handleSelect}
					selected={selected}
					checkbox={true}
				/>
				</details>*/}
			<h6>{t("Contacts.SELECTED", { count: selected?.length })}</h6>
			<hr className="m-1" />

			<UserPropsSelector
				selected={Cols}
				setSelected={setCols}
				tableClassName={props.tableClassName}
			/>
			<ErrorsCont errors={Error} />
			<div>
				<Button
					className={"btn-blue gap-3"}
					onClick={export_selected}
					disabled={IsExporting}
				>
					{t("Contacts.EXPORT")}
					{IsExporting && <FontAwesomeIcon icon={faUpload} />}
				</Button>
			</div>
		</div>
	);
}

const UserPropsSelector = (props) => {
	const { isSup } = useAccount();
	const ExportProps = getUserProps(isSup);
	const { selected, setSelected } = props;

	return (
		<>
			<div>{t("Contacts.EXPORT_FILEDS_MSG")}</div>
			<ScrollTable
				className={"UserPropsSelector " + props.tableClassName}
				itemId="id"
				items={ExportProps}
				cols={[
					{
						title: "Valeur",
						name: "title",
						sort: false,
					},
				]}
				checkbox={true}
				selected={selected}
				setSelected={setSelected}
				endText={false}
			/>
		</>
	);
};
